import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.js";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import { TECH_TYPE_TRANSLATION } from "@/game-palworld/constants/technology.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";

export const UnlockTable = ({
  techType,
  unlockIds,
}: {
  techType?: string;
  unlockIds?: readonly string[];
}) => {
  const { t } = useTranslation();
  const { buildings } = useWikiData();
  const { items } = useWikiData();

  if (!unlockIds || !items)
    return (
      <Card padding="0" title={t("palworld:unlocks", "Unlocks")}>
        <DataTableLoading borderless loadingRows={5} />
      </Card>
    );

  return (
    <Card
      padding="0"
      title={
        <span>
          {t("palworld:unlocks", "Unlocks")}{" "}
          <span className="shade2">({unlockIds.length.toLocaleString()})</span>
        </span>
      }
    >
      <DataTable
        borderless
        sortable={false}
        cols={[
          {
            display: t(...TECH_TYPE_TRANSLATION[techType]),
            align: "left",
          },
        ]}
        rows={unlockIds.map((id, i) => {
          const { imageUri, label } =
            techType === "building"
              ? Object.values(buildings).find((b) => b.internalId === id)
              : Object.values(items).find((i) => i.internalId === id);
          return [
            {
              value: i,
              display: (
                <span className="flex align-center gap-1 w-full">
                  <img
                    width={32}
                    height={32}
                    src={`${appURLs.CDN_PLAIN}/${imageUri}`}
                    alt={t(...label)}
                  />
                  <span className="item-name">{t(...label)}</span>
                </span>
              ),
              link: getWikiLink(
                techType === "building" ? `buildings:${id}` : `items:${id}`,
              ),
            },
          ];
        })}
      />
    </Card>
  );
};
