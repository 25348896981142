import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.js";
import { t as gt } from "i18next";
import { display } from "wiki:technology";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { TechList } from "@/game-palworld/components/TechList.jsx";
import { UnlockTable } from "@/game-palworld/components/UnlockTable.jsx";
import {
  WikiLayout,
  WikiSidebar,
} from "@/game-palworld/components/wiki.style.jsx";
import { TECH_TYPE_TRANSLATION } from "@/game-palworld/constants/technology.mjs";
import type { Technology as ITechnology } from "@/game-palworld/models/model-wiki.mjs";
import {
  getWikiData,
  useWikiData,
} from "@/game-palworld/utils/use-wiki-data.mjs";
import TechnologyCommon from "@/game-palworld/wiki/technology-common.mdx";
import { useRoute } from "@/util/router-hooks.mjs";

const Technology = () => {
  const { t } = useTranslation();
  const {
    parameters: [techId],
  } = useRoute();
  const { buildingTech, itemTech } = useWikiData();

  const tech = buildingTech?.[techId] ?? itemTech?.[techId];

  if (!techId || (buildingTech && itemTech && !tech))
    return (
      <WikiLayout title={["palworld:technology", "Technology"]}>
        <div className="main">
          <TechList itemTech={itemTech} buildingTech={buildingTech} />
        </div>
      </WikiLayout>
    );

  if (!tech)
    return (
      <WikiLayout title={["palworld:building", "Building"]}>
        <div className="sidebar">
          <Card loading style={{ height: "460px" }} />
        </div>
        <div className="main">
          <Card loading style={{ height: "148px" }} />
          <UnlockTable />
        </div>
      </WikiLayout>
    );

  const Component = display[techId] || TechnologyCommon;
  return (
    <WikiLayout title={tech.label}>
      <div className="sidebar">
        <SidebarCard tech={tech} />
      </div>
      <div className="main">
        <Component t={t} i18nKey="palworld:technology.common" tech={tech} />
        <UnlockTable techType={tech.techType} unlockIds={tech.unlockIds} />
      </div>
    </WikiLayout>
  );
};

const SidebarCard = ({ tech }: { tech: ITechnology }) => {
  const { t } = useTranslation();

  return (
    <WikiSidebar
      imgSrc={`${appURLs.CDN_PLAIN}/${tech.imageUri}`}
      itemName={tech.label}
    >
      <WikiSidebar.Info>
        <dt>{t("palworld:item.type", "Type")}</dt>
        <dd>
          <a href="/palworld/database/technology">
            {t("palworld:technology", "Technology")}
          </a>
        </dd>
        <dt>{t("palworld:item.category", "Category")}</dt>
        <dd>
          <a href={`/palworld/database/weapons?category=${tech.techType}`}>
            {t(...TECH_TYPE_TRANSLATION[tech.techType])}
          </a>
        </dd>
        <dt>{t("palworld:tech.pointType", "Point Type")}</dt>
        <dd>
          {tech.isAncientTech ? (
            <a href={`/palworld/database/weapons?type=ancient`}>
              {t("palworld:ancientTechPoints", "Ancient Tech Points")}
            </a>
          ) : (
            <a href={`/palworld/database/weapons?type=standard`}>
              {t("palworld:techPoint", "Tech Points")}
            </a>
          )}
        </dd>
        <dt>{t("palworld:tech.pointCost", "Point Cost")}</dt>
        <dd>{tech.pointCost.toLocaleString()}</dd>
        <dt>{t("palworld:item.levelRequirement", "Level Req.")}</dt>
        <dd>{tech.levelRequirement.toLocaleString()}</dd>
      </WikiSidebar.Info>
    </WikiSidebar>
  );
};

export function meta([techId]) {
  const { buildingTech, itemTech } = getWikiData();
  const tech = buildingTech?.[techId] ?? itemTech?.[techId];

  const techName = tech
    ? gt(...tech.label)
    : gt("palworld:technology", "Technology");
  if (techId && (!buildingTech || !itemTech || tech))
    return {
      title: [
        "palworld:weaponSeo.title",
        "{{techName}} in Palworld: Complete Profile, Breeding & Strategy Guide | Blitz",
        { techName },
      ],
      description: [
        "palworld:technologyLanding.desc",
        "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld technology.",
      ],
      subtitle: !IS_APP,
    };

  return {
    title: ["palworld:technologyLanding.title", "Palworld Technology"],
    description: [
      "palworld:technologyLanding.desc",
      "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld technology.",
    ],
    subtitle: !IS_APP,
  };
}

export default Technology;
